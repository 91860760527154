.votes {
  background-color: var(--white--900);
  height: 32px;
  padding: 0 10px;
  border-radius: 4px;
  color: var(--dark--900);
  font-weight: 600;
  font-size: 16px;

  display: flex;
  align-items: center;
  justify-content: center;
  span {
    transform: translateY(-1px) translateX(0px);
    font-size: 12px;
    opacity: 0.5;
  }
}
