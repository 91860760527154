.wrapper {
  min-width: 100px;
  width: 100%;
  max-width: 400px;
  position: relative;
}

.search {
  position: relative;
  width: 100%;
  input {
    border: none;
    outline: none;
    height: 48px;
    width: 100%;
    background-color: var(--dark--900);
    border-radius: var(--global--radios);
    padding: 0 82px 0 8px;
    font-size: 14px;
    color: var(--white--900);
    transition: all ease-in-out 300ms;
    box-shadow: 0px 0px 0px 0px var(--primary);
    &::placeholder {
      color: var(--dark--600);
    }
  }
  .submit {
    position: absolute;
    right: 4px;
    top: 4px;
    border-radius: var(--global--radios);
    height: 100%;
    height: 40px;
    width: 40px;
    display: flex;
    background-color: var(--primary);
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    transition: all ease-in-out 300ms;
    &:hover {
      background-color: var(--primary--hover);
    }
    &:disabled {
      background-color: var(--dark--700);
    }
  }
}

.loading {
  position: absolute;

  height: 100%;
  display: flex;
  align-items: center;
  right: 54px;
  top: 0;
  animation: rotating 1s linear infinite;
}
@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.modal {
  position: absolute;
  width: 100%;
  z-index: 1;
}
