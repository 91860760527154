.input__control {
  width: auto;
  height: auto;
  width: 100%;
  height: 60px;
  max-width: 400px;
  padding: 0 0 0 30px;
  background-color: var(--dark--700);
  border-radius: var(--global--radios);
  overflow: hidden;
  position: relative; 
  
  transition: padding 200ms ease-in-out;
  &[data-focus="true"] {
    padding: 0 0 0 0px;
    box-shadow: 0 0 0 1px var(--primary);
  }
  @media screen and (max-width: 768px){
      max-width: 100%;
  }
  
  .icon {
    position: absolute;
    height: 100%;
    width: 48px;
    display: flex;
    justify-content: center;
    left: 0;
    align-items: center;
    transition: left 200ms ease-in-out;
    &[data-focus="true"] {
      left: -60px;
    }
  }
  .eye {
    position: absolute;
    right: 0;
    z-index: 1;
    top: 0;
    height: 100%;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0.5;
  }
  .input {
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    background-color: var(--dark--700);
    font-size: 16px;
    color: var(--white--900);
    padding: 8px 16px;
    &::placeholder {
      color: var(--dark--500);
    }
  }
}
