.header {
  span {
    font-size: 14px;
    color: var(--white--900);
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.view {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 14px;
  color: var(--white--900);
}
.toggle {
  background-color: var(--dark--700);
  border-radius: var(--global--radios);
  display: flex;
  align-items: center;
  height: 40px;
  
  padding: 4px;

  .toggle__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    cursor: pointer;
    transition: ease-in-out 200ms background-color;
    border-radius: var(--global--radios);
    &:hover {
      background-color: var(--dark--600);
    }
    &[data-active="true"] {
      opacity: 1;
    }
    &[data-active="false"] {
      opacity: 0.5;
    }
  }
}
