.card {
  max-width: 500px;
  min-width: 300px;
}
.card__photo {
  height: 230px;
  width: 100%;
  position: relative;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 4px;
    display: block;
    border: 1px solid transparent;
  }
  .tag {
    position: absolute;
    left: 16px;
    bottom: -10px;
  }
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--global--radios);
    z-index: -1;

    background: linear-gradient(
      40deg,
      var(--primary) 4.6%,
      rgba(253, 253, 253, 0) 50%
    );
  }
}

.card__description {
  padding: 24px 0 0 0;

  a {
    color: var(--white--900);
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    transition: all ease-in-out 300ms;
    &:hover {
      color: var(--primary);
    }
  }
}
.info {
  display: flex;
  gap: 16px;
  font-size: 13px;
  line-height: 18px;
  color: var(--dark--600);
  padding-top: 16px;
  .info__inline {
    white-space: nowrap;
    color: inherit;
    display: inline;

    span {
      display: inline;
      color: var(--primary);
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
  }
}
