.s__blog {
  margin: var(--glabal--margin);
}
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  @media screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 35px;
    padding-bottom: 10px;
    grid-template-columns: repeat(3, 1fr);
    overflow-x: scroll;
  }
}

.head {
  padding: 10px 0 10px 0;
  display: flex;
  align-items: center;
  height: 65px;
  justify-content: space-between;
  h2 {
    font-size: 16px;
    color: var(--white--900);
    font-weight: 500;
    span {
      color: var(--primary);
      text-transform: uppercase;
    }
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }
}
