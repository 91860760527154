.nav {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 100;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
