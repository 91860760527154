.subscribe {
  display: flex;
  justify-content: center;
  padding: calc(var(--components-margin) * 3) 0;
  flex-direction: column;
  align-items: center;
}

.form {
  background-color: var(--dark--900);
  position: relative;
  height: 65px;
  width: 100%;
  max-width: 500px;
  padding: 8px 8px 8px 65px;
  border-radius: var(--global--radios);
  display: flex;
  justify-content: center;
  overflow: hidden;

  transition: padding ease-in-out 200ms;

  &[data-input-change="true"] {
    padding: 8px 8px 8px 8px;
    border: 1px solid var(--primary);
  }

  @media screen and (max-width: 768px) {
    padding: 8px 8px 8px 32px;
  }

  .icon {
    display: inline-flex;
    height: 100%;
    width: 65px;
    position: absolute;
    left: 0;
    top: 0;

    align-items: center;
    justify-content: center;
    transition: left ease-in-out 200ms;

    &[data-input-change="true"] {
      left: -65px;
    }

    @media screen and (max-width: 768px) {
      width: 40px;
    }
  }
  .input {
    outline: none;
    border: none;

    height: 100%;
    width: 100%;
    background-color: transparent;
    font-size: 16px;
    color: var(--white--900);
    padding: 16px 16px 16px 16px;
    transition: padding ease-in-out 200ms;
    @media screen and (max-width: 768px) {
      padding: 8px;
    }
  }
  .submit {
    border: none;
    outline: none;
    padding: 0 20px;
    white-space: nowrap;
    background-color: var(--primary);
    font-size: 14px;
    font-weight: 600;
    color: var(--white--900);
    border-radius: var(--global--radios);
    @media screen and (max-width: 768px) {
      font-size: 12px;
      padding: 0 10px;
    }
  }
}
.term {
  display: block;
  padding: 27px 0;
  max-width: 500px;
  text-align: center;
  font-size: 14px;
  span {
    color: var(--primary);
  }
}

.submited {
  padding: 0 20px;
  max-width: 575px;
  width: 150px;
  height: 65px - 16px;
  align-items: center;
  gap: 16px;
  font-weight: 600;
  border-radius: var(--global--radios);
  justify-content: center;
  display: inline-flex;
  background-color: var(--primary);
}
