.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vh;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.auth {
  display: flex;
  height: 100%;
  max-height: 700px;

  .left {
    flex: 55%;
    height: 100%;
  }
  .right {
    flex: 0 0 45%;
    height: 100%;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 16px 0;
    .left {
      height: 200px;
    }
    .right {
      flex: 0 0 100%;
    }
  }
}
.photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
