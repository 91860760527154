.description {
  display: flex;
  max-width: 224px;
  gap: 16px;
}
.game {
  padding-top: 14px;
  overflow: hidden;
  color: var(--white--900);
  padding-right: 16px;

  a {
    font-size: 14px;
    font-weight: 600;
    color: inherit;
    display: flex;
    white-space: nowrap;
    will-change: transform;
    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
    transition: all ease-in-out 300ms;
    &:hover {
      color: var(--primary);
    }
  }
  span {
    opacity: 0.5;
    font-size: 14px;
    font-weight: 300;
    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }
}

.options {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    width: 35px;
    height: 35px;
    background-color: transparent;
    border-radius: 4px;
    transition: all ease-in-out 300ms;
    &:hover {
      background-color: var(--dark--700);
    }
  }
}
