.form {
  background-color: var(--dark--900);
  width: 100%;
  height: 100%;
  max-width: 520px;
  padding: 32px 26px;
  h1 {
    color: var(--white--900);
    font-weight: 600;
    font-size: 24px;
    max-width: 250px;
    margin-bottom: 8px;
    @media screen and (max-width: 768px) {
      max-width: 300px;
    }
  }
  p {
    color: var(--dark--500);
    font-weight: 400;
    font-size: 14px;
  }
  @media screen and (max-width: 768px){
    height: auto;
  }
}

.type {
  color: var(--white--900);
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 26px;
  a {
    color: var(--primary);

  }
}

.form__control {
  padding-top: 24px;
  display: grid;
  grid-template-columns: repeat(auto, 1fr);
  gap: 16px;
  margin: 16px 0;
}

.form__buttons {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px 0;

  .submit {
    height: 50px;
    background-color: var(--primary);
    outline: none;
    border: none;
    font-size: 16px;
    border-radius: 4px;
    padding: 0 32px;
    color: var(--white--900);
    transition: background-color ease-in-out 300ms;
    &:hover {
      background-color: var(--primary--hover);
    }
  }
  span {
    font-size: 14px;
    color: var(--primary);
  }
  .icon__btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background-color: var(--dark--700);
    width: 50px;
    height: 50px;
    border-radius: var(--global--radios);
    transition: background-color ease-in-out 200ms;
    &:hover {
      background-color: var(--dark--600);
    }
  }
}
