:root {
    /* palete */
    --dark--900: #1e2127;
    --dark--800: #25262e;
    --dark--700: #35363e;
    --dark--600: #5e6167;
    --dark--500: #8c9097;
    --white--900: #f5f5f5;
    /* palete */
    /* Brand colors */

    

    /* guadians: #e62c70  */
    /* purperain: #9941f2  */
    --primary: #e62c70;
    --primary--hover: #c4215d;
    /* Brand colors */
    /* control */
    --components-margin: 30px;
    /* control */
    /* especial colors  */
    --gradient: linear-gradient(91.99deg, var(--primary) -8.15%, #00ffe0 124.64%);
    /* especial colors  */
    /* layout vars */
    --global--radios: 4px;
    --glabal--margin: var(--components-margin) 0;
    /* layout vars */
}

* {
    padding: 0;
    margin: 0;

    box-sizing: border-box;
}

a {
    text-decoration: none;
}

button {
    cursor: pointer;
}

/* font */
body {
    background-color: var(--dark--800);
    color: var(--white--900);
}
html,
body {
    overflow-x: hidden;
}

body,
textarea,
input,
button,
p {
    font-family: "Poppins", sans-serif;
}
button {
    cursor: pointer;
}

a.active {
    color: var(--white--900);
    position: relative;
    transition: all ease-in-out 300ms;
}
a.active::after {
    content: "";
    position: absolute;
    bottom: -10px;
    width: 10px;
    height: 2px;
    background-color: var(--primary);
    transform: translateX(-50%);
    left: 50%;

    animation: nav__Dots forwards ease-in 300ms;
}

@keyframes nav__Dots {
    from {
        transform: translateX(-50%) translateY(10px);
    }
    to {
        transform: translateX(-50%) translateY(0px);
    }
}
.swiper-container {
    overflow: initial;
}
