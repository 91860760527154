.loading {
  height: 100%;
  display: flex;
  align-items: center;
  animation: rotating 2s linear infinite;
}
@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
