.s__address {
  background-color: var(--dark--700);
}
.address {
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    text-align: center;
    flex-direction: column;
    padding: 40px 16px;
    height: auto;
    gap: 24px;
  }
}
.author {
  font-size: 15px;
  color: var(--dark--600);
  font-weight: 500;
  transition: color ease-in-out 200ms;
  a {
    color: var(--primary);
  }
}
.contact {
  ul {
    list-style: none;
    display: flex;
    gap: 24px;
    @media screen and (max-width: 768px) {
      text-align: center;
      flex-direction: column;
    }
    li {
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-size: 14px;
        color: var(--dark--600);
        transition: color ease-in-out 200ms;
        svg path {
          fill: var(--primary);
        }
        &:hover {
          color: var(--primary);
        }
      }
    }
  }
}
