.head {
  display: flex;
  height: 32px;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  .title {
    font-size: 16px;
    height: 25px;
    display: flex;
    align-items: center;
    font-weight: 500;
    padding: 0 16px;
    color: var(--white--900);
    text-transform: uppercase;
    background-color: var(--primary);
    border-radius: var(--global--radios);
    white-space: nowrap;
    @media screen and (max-width: 768px) {
      font-size: 12px;
      padding: 0 8px;
    }
  }
  hr {
    width: 100%;
    border: none;
    border-top: 1px solid var(--primary);
    @media screen and (max-width: 768px) {
      opacity: 0.4;
    }
  }
}
