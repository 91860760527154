.tag {
  padding: 0 16px;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white--900);
  color: var(--dark--900);
  font-size: 16px;
  font-weight: 600;
  height: 30px;
  border-radius: var(--global--radios);
  text-transform: uppercase;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    padding: 0 8px;
    height: 24px;
  }
}
