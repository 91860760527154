.nav {
  padding-top: 38px;
  background-color: var(--dark--700);
  padding-bottom: 20px;
}
.nav__item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
}
.right {
  flex: 1 0 0;
  height: 100%;
}
.center {
  flex: 1 0 0;
}
.left {
  flex: 1 0 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.right {
  display: flex;
  gap: 24px;
  align-items: center;
}

[data-home="true"] {
  position: absolute;
  z-index: 999;
  width: 100%;
  left: 0;
  top: 0;
  background-color: transparent;
}
