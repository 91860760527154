.modal {
  width: 100%;
  background-color: var(--dark--900);
  margin-top: -3px;
  border-radius: var(--global--radios);
  z-index: -1;

  padding: 10px 8px;
}
.head {
  display: flex;
  color: var(--dark--600);
  font-weight: 700;
  font-size: 12px;
  padding: 4px 0;
  border-top: 1px solid var(--dark--600);
}
.genres__grid {
  display: grid;
  grid-template-rows: repeat(auto, 1fr);
  gap: 10px;
  padding-top: 4px;
}
.action {
  display: flex;
  justify-content: center;
  padding: 4px 0;
  a {
    font-size: 14px;
    color: var(--dark--600);
    font-weight: 400;
    transition: color ease-in-out 200ms;
    &:hover {
      color: var(--primary);
    }
  }
}
