.s__banner {
  background: var(--dark--900);
  height: 100vh;
  max-height: 287px;
  width: 100%;
  position: relative;
  display: flex;
  margin: calc(var(--components-margin) + 10px) 0 var(--components-margin) 0;

  .left {
    flex: 0 0 40%;
  }
  .right {
    flex: 0 0 60%;
  }
  @media screen and (max-width: 1024px) {
    .left {
      flex: 0 0 50%;
    }
    .right {
      flex: 0 0 50%;
    }
  }
  @media screen and (max-width: 768px) {
    flex-flow: wrap;
    height: auto;
    max-height: none;
    .left {
      flex: 0 0 100%;
      height: auto;
      order: 2;
    }
    .right {
      flex: 0 0 100%;
      height: 200px;
      order: 1;
    }
  }
}
.tag {
  position: absolute;
  top: -14px;
  left: 30px;
}
.left {
  .left__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    color: var(--white--900);
    @media screen and (max-width: 768px) {
      padding: 16px;
    }
    .title {
      font-size: 24px;
      color: inherit;
      font-weight: 600;
      display: block;
      transition: color ease-in-out 200ms;
      @media screen and (max-width: 768px) {
        font-size: 20px;
      }
      &:hover {
        color: var(--primary);
      }
    }
    .publisher {
      padding-top: 4px;
      color: var(--dark--600);
      font-size: 16px;
      opacity: 0.5;
      @media screen and (max-width: 768px) {
        font-size: 12px;
      }
    }
    p {
      color: inherit;
      font-size: 14px;
      padding-top: 8px;
      max-width: 80%;
      @media screen and (max-width: 768px) {
        font-size: 12px;
        max-width: 95%;
      }
    }
  }
}
.right {
  .right__content {
    height: 100%;
    width: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
.button__group {
  margin-top: 16px;

  .action__button {
    display: inline-flex;
    align-items: center;
    gap: 16px;

    outline: none;

    padding: 0 16px;
    height: 48px;
    position: relative;

    border-style: solid;
    border-width: 1px;
    border-image: linear-gradient(
        45deg,
        var(--primary) -10%,
        rgba(47, 234, 200, 0) 60%
      )
      1;
    border-radius: 4px;

    font-size: 16px;
    font-weight: 400;
    color: var(--white--900);
    white-space: nowrap;
    transition: all ease-in-out 300ms;
    svg path {
      fill: var(--white--900);
      transition: all ease-in-out 300ms;
    }
    &:hover {
      gap: 24px;
      color: var(--primary);
      border-image: linear-gradient(
          45deg,
          var(--primary) 20%,
          rgba(47, 234, 200, 0) 80%
        )
        1;
      svg path {
        fill: var(--primary);
      }
    }
  }
}
