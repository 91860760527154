.s__slider {
  margin: var(--glabal--margin);
}
.head {
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  hr {
    width: 100%;
    border: none;
    border-top: 1px solid var(--primary);
    @media screen and (max-width: 768px) {
      opacity: 0.4;
    }
  }
}
.title {
  font-size: 16px;
  height: 25px;
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 0 16px;
  color: var(--white--900);
  text-transform: uppercase;
  background-color: var(--primary);
  border-radius: var(--global--radios);
  white-space: nowrap;
  @media screen and (max-width: 768px) {
    font-size: 12px;
    padding: 0 8px;
  }
}

.control {
  display: flex;
  gap: 8px;
  @media screen and (max-width: 768px) {
    display: none;
  }
  .control__button {
    border-radius: var(--global--radios);

    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    transition: all ease-in-out 300ms;
    background-color: var(--primary);

    &:hover {
      background: var(--dark--700);
      svg path {
        transition: all ease-in-out 300ms;
        opacity: 0.6;
      }
    }
  }
  [name="prev"] {
    transform: rotate(180deg);
  }
}

.slide {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    right: -80%;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      270deg,
      #25262e 50%,
      rgba(37, 38, 46, 0.9) 80%,
      rgba(37, 38, 46, 0) 100%
    );
    z-index: 10;
    pointer-events: none;
  }
}
