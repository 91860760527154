.game__photo {
  border-radius: 4px;
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 300px;
  cursor: pointer;
  background-color: var(--dark--700);
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: transform ease-in-out 200ms;
  }
  &:hover {
    img {
      transform: scale(1.03);
    }
  }
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(
      360deg,
      rgba(37, 38, 46, 0.81) 5.05%,
      rgba(37, 38, 46, 0) 47.58%,
      rgba(37, 38, 46, 0.611719) 100%
    );
    z-index: 50;
  }
  @media screen and (max-width: 768px) {
    height: 270px;
  }
}
.game__state {
  background: var(--primary);
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  height: 5px;
  z-index: 99;
  border-radius: 4px 4px 0 0;
}

.menu__top {
  display: flex;
  position: absolute;
  top: 0;
  z-index: 99;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px;
}

.menu__bottom {
  position: absolute;
  bottom: 0;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 16px 16px 16px;
  .state__button {
    border: 1px solid #f19c37;
    border-radius: 4px;
    background-color: transparent;
    font-size: 14px;
    color: var(--white--900);
    height: 30px;
    display: inline-flex;
    align-items: center;
    padding: 4px 8px;
    &:nth-child(2) {
      border: 1px solid #3eaeb7;
    }
    &:nth-child(3) {
      border: 1px solid #44d653;
    }
  }
}

.example-container {
  div {
    background: white;
    border-radius: 30px;
    width: 150px;
    height: 150px;
  }
}
