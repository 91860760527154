.container {
    max-width: 1216px;
    margin: 0 auto;
    padding: 0 16px;
}

@media screen and (max-width: 1440px) {
    .container {
        max-width: 1216px;
        margin: 0 auto;
    }
}

@media screen and (max-width: 1024px) {
    .container {
        max-width: 940px;
        margin: 0 auto;
    }
}

@media screen and (max-width: 768px) {
    .container {
        max-width: 610px;
        margin: 0 auto;
        padding: 0 8px;
    }
}
